import React from 'react';
import {StyledButton} from "./styled/StyledButton";
import {colors} from "../colors";
import CircleSVG from "../images/CircleSVG";
import {Tooltip} from "antd";
import styled from "styled-components";

const StyledOtherSite = styled.div`
    ${StyledButton} {
        width: 100%;

        &:first-child {
            max-width: 56px;
            max-height: 56px;
            padding: 18px
        }
    }
`;

function OtherSite() {
    return (
        <StyledOtherSite>
            <Tooltip title={"Другие сайты"}>
                <StyledButton className={"white"} style={{background: colors.white0}}>
                    <CircleSVG color={colors.lightBlue2}/>
                </StyledButton>
            </Tooltip>
        </StyledOtherSite>
    );
}

export default OtherSite;