import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {StyledButton} from "../components/styled/StyledButton";
import {uiScale} from "../config";
import {StyledInput} from "../components/styled/StyledInput";
import {DatePicker, Form, Select, Space, TimePicker, Upload} from "antd";
import {StyledSelect} from "../components/styled/StyledSelect";
import UploadSVG from "../images/UploadSVG";
import YourSite from "../components/YourSite";
import OtherSite from "../components/OtherSite";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    changeCurrentTrackerId,
    changeNewProjectAction,
    createTrackerAction,
    editTrackerAction,
    getTrackerStatusAction
} from "../redux/pageSlice";
import * as _ from "lodash/fp";
import dayjs from "dayjs";
import History from "../utils/history";
import {StyledDatePicker} from "../components/styled/StyledDatePicker";
import {StyledTimePicker} from "../components/styled/StyledTimePicker";
import urlRegex from "url-regex";
import StatusCircleSVG from "../images/StatusCircleSVG";
import {StyledNumberInput} from "../components/styled/StyledNumberInput";
import {useSearchParams} from "react-router-dom";

const TimePickWrap = styled.div`
    display: flex;
    flex-direction: column;
    color: ${({theme}) => theme.gray2};
    font-size: 18px;
    font-weight: 500;
    line-height: 18px;
    gap: 10px;
    padding-bottom: 26px;
    padding-top: 20px;

    span {
        display: flex;
        gap: 10px;
        align-items: center;

        .ant-picker {
            &:first-child {
                margin-right: 30px;
            }
        }
    }
`;

const StyledNewProjectPage = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: calc(40px * ${uiScale});

    .ant-form {
        width: 100%;

        .ant-form-item-label {
            width: 220px;
            display: flex;
            align-items: center;
        }

        label {
            color: ${({theme}) => theme.gray2};
            font-size: 18px;
            font-weight: 500;
            line-height: 26px;
        }
    }

    > span {
        display: flex;
        flex-direction: column;
        align-items: center;

        &.stage2 {
            display: flex;
            padding: calc(40px * ${uiScale}) calc(65px * ${uiScale});
            border-radius: calc(14px * ${uiScale});
            border: calc(1px * ${uiScale}) solid ${({theme}) => theme.blue};
            align-items: flex-start;

            .blocks {
                display: flex;
                gap: 40px;
                align-items: flex-start;
                flex-wrap: wrap;

                p {
                    font-size: calc(32px * ${uiScale});
                    text-align: start;
                }

                > span {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    background: ${({theme}) => theme.lightBlue};
                    border-radius: 14px;
                    padding: 40px 75px;
                    max-width: 600px;
                }
            }

        }

        &.stage3 {
            display: flex;
            align-items: flex-start;
            padding: calc(52px * ${uiScale});
            border-radius: calc(14px * ${uiScale});
            border: calc(1px * ${uiScale}) solid ${({theme}) => theme.blue};
            max-width: 765px;
            width: 100%;

            .label {
                font-size: calc(18px * ${uiScale});
                font-weight: 500;
                margin-bottom: 25px;
                color: ${({theme}) => theme.gray};
            }

            .buttons {
                display: flex;
                width: 100%;
                gap: 40px;
                margin-top: 36px;

                ${StyledButton} {
                    width: 100%;
                }
            }
        }

        &.stage5 {
            .ant-space {
                width: 100%;

                .ant-space-item {
                    &:first-child {
                        width: 100%;
                    }
                }
            }

            .code_status {
                display: flex;
                justify-content: space-between;
                width: 100%;
                border-radius: 8px;
                align-items: center;
                color: ${({theme}) => theme.black};
                font-size: 20px;
                font-weight: 500;
                line-height: 26px;
                padding: 14px;
                box-shadow: 0 0 10px 0 #00000029;
            }
        }

        &.stage4_1 {

            .label {
                span {
                    color: ${({theme}) => theme.blue};
                }
            }

            .ant-input {
                margin-bottom: 25px;
            }

            .ant-upload {
                ${StyledButton} {
                    font-size: 24px;

                    svg {
                        margin-left: 46px;
                    }
                }
            }

            .ant-upload-wrapper, .ant-upload, ${StyledButton} {
                width: 100%;
            }

            .bottomLabel {
                font-size: 14px;
                margin-top: 25px;
            }
        }

        &.stage8 {
            .label {
                margin-bottom: 36px;
            }

            ${StyledButton} {
                width: 100%;

                &:first-of-type {
                    margin-bottom: calc(18px * ${uiScale});
                }

                &:last-of-type {
                    background: ${({theme}) => theme.white0} !important;
                    color: ${({theme}) => theme.blue} !important;
                }
            }
        }
    }

    p {
        text-align: center;
        font-size: calc(42px * ${uiScale});
        line-height: calc(52px * ${uiScale});
        font-weight: 600;
        color: ${({theme}) => theme.black};
        max-width: calc(757px * ${uiScale});

        span {
            color: ${({theme}) => theme.blue};
        }
    }

    ${StyledButton} {
        font-size: calc(18px * ${uiScale});
        padding-right: calc(70px * ${uiScale});
        padding-left: calc(70px * ${uiScale});
    }
`;

function NewProjectPage() {

    const new_project = useAppSelector((state) => state.page.new_project);
    const trackerStatuses = useAppSelector((state) => state.page.trackerStatuses);
    const currentTrackerId = useAppSelector((state) => state.page.currentTrackerId);
    const dispatch = useAppDispatch();

    const [createLoading, setCreateLoading] = useState<boolean>(false)

    const [searchParams, setSearchParams] = useSearchParams();

    const resetValues = () => dispatch(changeNewProjectAction({
        Name: "",
        Domains: [],
        Type: "regular",
        Settings: {
            DayLimit: 5,
            External: "",
            DelayPopupSec: 5,
            Geo: [],
            CollectTimeStart: dayjs('10:00', "HH:mm"),
            CollectTimeEnd: dayjs('12:00', "HH:mm")
        }
    }))

    useEffect(() => {
        if (new_project?.Name !== "") {
            if (searchParams.get("stage") === "edit") {
                setCurrentStage("stage6")
            }
            if (searchParams.get("stage") === "edit_name") {
                setCurrentStage("stage3")
            }
        } else {
            setSearchParams({})
        }
    }, [searchParams]);

    useEffect(() => {
        return () => {
            dispatch(changeCurrentTrackerId(undefined))
            resetValues()
        }
    }, []);

    const stages = {
        stage1: <span className={"stage1"}>
            <p>Запустите идентификацию пользователей на свой сайт или сторонние сайты <span>всего за 4 шага</span></p>
            <StyledButton onClick={() => setCurrentStage("stage2")}>Создать новый проект</StyledButton>
        </span>,
        stage2: <span className={"stage2"}>
            <p>Запуск проекта</p>

            <div className="blocks">
                <span>
                <p>Запустить идентификацию контактов на <span>свой сайт</span></p>
                <StyledButton onClick={() => {
                    setCurrentStage("stage3")
                    dispatch(changeNewProjectAction({...new_project, Type: "regular"}))
                }}>Запустить</StyledButton>
            </span>

            <span>
                <p>Запустить идентификацию контактов на <span>другие сайты</span></p>
                <StyledButton onClick={() => {
                    setCurrentStage("stage3")
                    dispatch(changeNewProjectAction({...new_project, Type: "external"}))
                }}>Запустить</StyledButton>
            </span>
            </div>

        </span>,
        stage3: <span className={"stage3"}>
            <p>Название проекта</p>
            <span className="label">До 250 символов</span>
            <StyledInput maxLength={250} value={new_project.Name} onChange={({currentTarget: {value}}) => {
                dispatch(changeNewProjectAction({...new_project, Name: value}))
            }} placeholder={"Введите название"}/>
            <div className="buttons">
                {new_project.Type === "external" ? <OtherSite/> : <YourSite/>}
                <StyledButton
                    disabled={!new_project.Name?.length}
                    onClick={() => {
                        if (searchParams.get("stage") === "edit_name") {
                            dispatch(editTrackerAction(new_project)).unwrap().then(() => {
                                History.push("/")
                                resetValues()
                            })
                        } else {
                            new_project.Type === "external" ? setCurrentStage("stage4_1") : setCurrentStage("stage4")
                        }
                    }}>Далее</StyledButton>
            </div>
        </span>,
        stage4: <span className={"stage3 stage4"}>
            <p>1. Добавить код на сайт</p>

            <span className="label">Размести этот код у себя на сайте</span>
            {/*<span className="label">инструкция (поп ап)</span>*/}
            {/*<span className="label">Важно! Не закрывайте это окно пока не добавите код на сайт, иначе проект не сохранится</span>*/}
            <StyledInput
                style={{marginBottom: 20}}
                value={`<script src="https://tracker.vaicom.tech/tracker" defer></script>`}
            /><StyledInput
            value={new_project.Domains?.[0] || ""}
            placeholder={"Введите url сайта"}
            onChange={({currentTarget: {value}}) => {
                dispatch(changeNewProjectAction({...new_project, Domains: [value]}))
            }}
        />
            <div className="buttons">
                {new_project.Type === "external" ? <OtherSite/> : <YourSite/>}
                <StyledButton
                    // disabled={!new_project.Domains?.[0]?.length}
                    loading={createLoading}
                    disabled={!urlRegex({strict: false}).test(new_project.Domains?.[0])}
                    onClick={() => {
                        setCreateLoading(true)
                        dispatch(createTrackerAction({...new_project, Settings: undefined})).unwrap().then(() => {
                            setCurrentStage("stage5")
                        }).finally(() => {
                            setCreateLoading(false)
                        }).catch(() => {

                        })
                    }}>Далее</StyledButton>
            </div>
        </span>,
        stage4_1: <span className={"stage3 stage4_1"}>
            <p>1. Добавить сайты</p>

            <span className="label">
                Добавьте ссылки на сайты для сбора или загрузите список в формате<br/>
            (указать все форматы)<br/>
            <span>примеры ссылок для загрузки (поп ап)</span></span>
            <span className="label">Кол-во ссылок: {new_project?.Domains?.length || 0}</span>
            {/*<StyledInput placeholder={"Вставить ссылки"}/>*/}
            <StyledSelect
                placeholder={"Вставить ссылки"}
                value={new_project?.Domains || []}
                mode="tags"
                onChange={(value, option) => {
                    dispatch(changeNewProjectAction({...new_project, Domains: value}))
                }}
                style={{width: '100%', marginBottom: 25}}
                tokenSeparators={[' ', ',']}
            />
                <Upload>
                    <StyledButton disabled>Загрузить файл <UploadSVG/></StyledButton>
                </Upload>
            <span
                className="label bottomLabel">Формат: TXT,CSV,XLS или XLSX до 130 МБ.  Ссылки на сайты в формате <span>expertteam.pro</span></span>

            <div className="buttons">
                <OtherSite/>
                <StyledButton
                    loading={createLoading}
                    disabled={(!new_project?.Domains?.length || !new_project?.Domains?.every(v => urlRegex({strict: false}).test(v)))}
                    onClick={() => {
                        setCreateLoading(true)
                        dispatch(createTrackerAction({...new_project, Settings: undefined})).unwrap().then(() => {
                            setCurrentStage("stage6")
                        }).finally(() => {
                            setCreateLoading(false)
                        }).catch(() => {

                        })
                    }}>Далее</StyledButton>
            </div>
        </span>,
        stage4_1_1: <span className={"stage3 stage4_1"}>
            <p>1. Проверка ссылок</p>

            <StyledInput placeholder={"Добавьте ссылку"}/>
            <div className="buttons">
                <OtherSite/>
                <StyledButton onClick={() => {
                    setCurrentStage("stage6")
                }}>Далее</StyledButton>
            </div>
        </span>,
        stage5: <span className={"stage3 stage5"}>
            <p>2. Проверка кода</p>

            <span className="label">Нажмите на кнопку «Проверить» если код активен, переходите к настройкам. Если код не активен, вернитесь назад и проверьте установку по инструкции</span>
            <Space>
                <span className="code_status">
                    {(currentTrackerId !== undefined && trackerStatuses[currentTrackerId]?.Online) ? <>Код
                        активен <StatusCircleSVG/></> : <>Код неактивен <StatusCircleSVG className={"red"}/></>}
                </span>
                <StyledButton
                    disabled={currentTrackerId !== undefined && trackerStatuses[currentTrackerId]?.Online}
                    onClick={() => {
                        dispatch(getTrackerStatusAction())
                    }}>Проверить</StyledButton>
            </Space>
            <div className="buttons">
                {new_project.Type === "external" ? <OtherSite/> : <YourSite/>}

                <StyledButton
                    disabled={!(currentTrackerId !== undefined && trackerStatuses[currentTrackerId]?.Online)}
                    onClick={() => {
                        setCurrentStage("stage6")
                    }}>Далее</StyledButton>
            </div>
        </span>,
        stage6: <span className={"stage3 stage6"}>
            <p>3. Настройки</p>
            <Form colon={false}>
                <Form.Item label={"Время сбора (МСК)"}>
                    <TimePickWrap>
                        <span>с<StyledTimePicker
                            value={new_project?.Settings?.CollectTimeStart ? dayjs(new_project?.Settings?.CollectTimeStart) : undefined}
                            onChange={(date, dateString) => {
                                dispatch(changeNewProjectAction(_.set(["Settings", "CollectTimeStart"], date, new_project)))
                            }} format={'HH:mm'}/>
                        До<StyledTimePicker
                                value={new_project?.Settings?.CollectTimeEnd ? dayjs(new_project?.Settings?.CollectTimeEnd) : undefined}
                                onChange={(date, dateString) => {
                                    dispatch(changeNewProjectAction(_.set(["Settings", "CollectTimeEnd"], date, new_project)))
                                }} format={'HH:mm'}/></span>
                    </TimePickWrap>
                    {/*<StyledDatePicker value={new_project?.Settings?.CollectDateStart} needConfirm*/}
                    {/*            onChange={(date, dateString) => {*/}
                    {/*                dispatch(changeNewProjectAction(_.set(["Settings", "CollectDateStart"], date, new_project)))*/}
                    {/*            }} renderExtraFooter={() => <TimePickWrap>*/}
                    {/*    Выбрать время (по МСК)*/}
                    {/*    <span>с<StyledTimePicker value={new_project?.Settings?.CollectTimeStart} onChange={(date, dateString) => {*/}
                    {/*        console.log(date);*/}
                    {/*        dispatch(changeNewProjectAction(_.set(["Settings", "CollectTimeStart"], date, new_project)))*/}
                    {/*    }} format={'HH:mm'}/>*/}
                    {/*    До<StyledTimePicker defaultValue={new_project?.Settings?.CollectTimeEnd} onChange={(date, dateString) => {*/}
                    {/*            dispatch(changeNewProjectAction(_.set(["Settings", "CollectTimeEnd"], date, new_project)))*/}
                    {/*        }} format={'HH:mm'}/></span>*/}
                    {/*</TimePickWrap>}/>*/}
                </Form.Item>
                <Form.Item label={"Дневной лимит"}>
                    <StyledNumberInput controls={false} value={new_project?.Settings?.DayLimit} onChange={(value) => {
                        dispatch(changeNewProjectAction(_.set(["Settings", "DayLimit"], value, new_project)))
                    }}/>
                </Form.Item>
                <Form.Item label={"Интеграции"}>
                    <StyledSelect value={new_project?.Settings?.External} onChange={(value) => {
                        dispatch(changeNewProjectAction(_.set(["Settings", "External"], value, new_project)))
                    }} placeholder={"Выбрать CRM"} options={[{
                        label: "Google Таблицы",
                        value: "Google Таблицы"
                    }, {
                        label: "Битрикс 24",
                        value: "Битрикс 24"
                    }, {
                        label: "АмоCRM",
                        value: "АмоCRM"
                    }, {
                        label: "Мегаплан",
                        value: "Мегаплан"
                    }, {
                        label: "Свой Webhook",
                        value: "Свой Webhook"
                    }, {
                        label: "Настроить позже",
                        value: ""
                    }]}/>
                </Form.Item>
            </Form>
            <div className="buttons">
                {new_project.Type === "external" ? <OtherSite/> : <YourSite/>}
                {/*<StyledButton onClick={() => {*/}
                {/*    new_project?.Type === "regular" ? setCurrentStage("stage5") : setCurrentStage("stage4_1")*/}
                {/*}}>Назад</StyledButton>*/}
                <StyledButton loading={createLoading} onClick={() => {
                    // setCreateLoading(true)
                    dispatch(editTrackerAction(new_project)).unwrap().then(() => {
                        if (searchParams.get("stage") === "edit") {
                            History.push("/")
                        } else {
                            setCurrentStage("stage8")
                        }
                        resetValues()
                    })
                    // setCurrentStage("stage7")
                    // dispatch(createTrackerAction(new_project)).unwrap().then(() => {
                    //     setCurrentStage("stage8")
                    // }).finally(() => {
                    //     setCreateLoading(false)
                    // }).catch(() => {
                    //
                    // })
                }}>Далее</StyledButton>
            </div>
        </span>,
        stage7: <span className={"stage3 stage7"}>
            <p>4. Запуск</p>
            <Form colon={false}>
                {/*<Form.Item label={"Кол-во сайтов"}>*/}
                {/*    <StyledInput/>*/}
                {/*</Form.Item>*/}
                {/*<Form.Item label={"День и время сбора"}>*/}
                {/*    <DatePicker renderExtraFooter={() => <>*/}
                {/*        Выбрать время (по МСК)*/}
                {/*        с<TimePicker format={'HH:mm'}/>*/}
                {/*        по<TimePicker format={'HH:mm'}/>*/}
                {/*    </>}/>*/}
                {/*</Form.Item>*/}
                <Form.Item label={"Лимит (день)"}>
                    <StyledInput placeholder={"100"} onChange={({currentTarget: {value}}) => {
                        dispatch(changeNewProjectAction(_.set(["Settings", "DayLimit"], value, new_project)))
                    }}/>
                </Form.Item>
            </Form>
            <div className="buttons">
                {new_project.Type === "external" ? <OtherSite/> : <YourSite/>}
                <StyledButton onClick={() => {
                    setCurrentStage("stage6")
                }}>Назад</StyledButton>
                <StyledButton onClick={() => {
                    setCurrentStage("stage8")
                }}>Далее</StyledButton>
            </div>
        </span>,
        stage8: <span className={"stage3 stage8"}>
            <p>Проект успешно запущен!</p>
            <span className="label">В ближайшие 2 часа начнется идентификация.</span>
                <StyledButton onClick={() => History.push("/")}>Действующие проекты</StyledButton>
                <StyledButton onClick={() => {
                    dispatch(changeNewProjectAction({...new_project, Type: "regular"}))
                    setCurrentStage("stage1")
                }}>Добавить новый проект</StyledButton>
        </span>
    }

    const [currentStage, setCurrentStage] = useState<keyof typeof stages>("stage1")

    return (
        <StyledNewProjectPage>
            {stages[currentStage]}
        </StyledNewProjectPage>
    );
}

export default NewProjectPage;