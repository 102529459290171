import React, {SVGProps} from 'react';
import styled from "styled-components";

const StyledStatusCircleSVG = styled.svg`
    &.red {
        circle{
            &:first-child{
                fill: #FF9999;
            }
            &:last-child{
                fill: #FF0000;
            }
        }
    }
`;

function StatusCircleSVG(props: SVGProps<SVGSVGElement>) {
    return (
        <StyledStatusCircleSVG {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7" cy="7" r="7" fill="#BBFFC6"/>
            <circle cx="7" cy="7" r="4" fill="#00FF28"/>
        </StyledStatusCircleSVG>

    );
}

export default StatusCircleSVG;