import React, {SVGProps} from 'react';
import styled from "styled-components";

const StyledCircleSVG = styled.svg<{$color?: string}>`
    circle {
        fill: ${({$color}) => $color || "#FFD600"};
    }
`;

function CircleSVG({color, ...props}: SVGProps<SVGSVGElement>) {
    return (
        <StyledCircleSVG $color={color} {...props} width="22" height="21" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="11.096" cy="10.5601" r="10.4192" fill="#FFD600"/>
        </StyledCircleSVG>

    );
}

export default CircleSVG;