import React from 'react';
import {Table, TableProps, Tooltip} from "antd";
import styled from "styled-components";
import StopBtnSVG from "../images/StopBtnSVG";
import StartBtnSVG from "../images/StartBtnSVG";
import DownloadSVG from "../images/DownloadSVG";
import CircleSVG from "../images/CircleSVG";
import {colors} from "../colors";
import EditPenSVG from "../images/EditPenSVG";
import StatusCircleSVG from "../images/StatusCircleSVG";
import {TrackerType} from "../utils/types";
import {api} from "../config";
import {useAppDispatch} from "../redux/hooks";
import {
    changeCurrentTrackerId,
    changeNewProjectAction,
    changeTrackerStateAction,
    deleteTrackerAction
} from "../redux/pageSlice";
import dayjs from "dayjs";
import History from "../utils/history";

const StyledStyledTable = styled(Table)<TableProps>`
    width: 100%;
    font-size: 16px;
    font-weight: 500;

    .ant-table {
        * {
            line-height: 20px;
        }
    }

    .editSvg {
        cursor: pointer;
    }

    .nameField, .integrationField {
        display: flex;
        flex-direction: column;
        gap: 14px;

        span {
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .integrationField {
        color: ${({theme}) => theme.gray2};
    }

    .downloadBtn {
        color: ${({theme}) => theme.blue};
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
    }

    .limits {
        color: ${({theme}) => theme.gray2};
        font-size: 14px;
    }

    .ant-table-thead {
        font-size: 16px;

        .ant-table-cell {
            padding: 15px 10px;
            background: ${({theme}) => theme.blue};
            color: ${({theme}) => theme.white0};

            &:first-child {
                border-start-start-radius: 4px !important;
            }

            &:last-child {
                border-start-end-radius: 4px !important;
            }

            &:before {
                display: none;
            }
        }
    }

    .ant-table-tbody {
        font-size: 16px;

        .ant-table-row {
            .ant-table-cell {
                padding: 15px 10px;
                color: ${({theme}) => theme.black};
                border-right: 2px solid ${({theme}) => theme.lightBlue};
                border-bottom: 2px solid ${({theme}) => theme.lightBlue};

                &:first-child {
                    border-left: 2px solid ${({theme}) => theme.lightBlue};
                }
            }

            &:last-child {
                .ant-table-cell {
                    &:first-child {
                        border-bottom-left-radius: 4px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }
`;

function StyledTable({data, dateFilter, rangePickerValue}: any) {
    const dispatch = useAppDispatch();

    const columns: TableProps<TrackerType>['columns'] = [
        {
            title: 'Название проекта',
            dataIndex: 'Name',
            render: (text, record) => <span className={"nameField"}><span>{record.Type === "regular" ?
                <CircleSVG color={colors.orange}/> :
                <CircleSVG color={colors.lightBlue2}/>}
                <EditPenSVG className={"editSvg"} onClick={() => {
                    dispatch(changeCurrentTrackerId(record.Id))
                    dispatch(changeNewProjectAction(record))
                    History.push("/project/new?stage=edit_name")
                }}/>
            </span><span>{text}</span></span>,
        },
        {
            title: 'Статус',
            dataIndex: 'Enabled',
            width: 60,
            render: (value, record) => <span
                style={{cursor: 'pointer', display: "flex", justifyContent: "center", alignItems: "center"}}
                onClick={() => {
                    dispatch(changeTrackerStateAction({
                        Id: record.Id,
                        new_state: record.Enabled ? "disable" : "enable"
                    }))
                }}>
                <Tooltip title={value ? "Stop" : "Start"}>
                    {!value ? <StopBtnSVG/> : <StartBtnSVG/>}
                </Tooltip>
            </span>
        },
        {
            title: 'Время работы (МСК)',
            render: (value, record) => (record?.Settings?.CollectTimeStart && record?.Settings?.CollectTimeEnd) ? `С ${dayjs(record?.Settings?.CollectTimeStart).format("HH:mm")} до ${dayjs(record?.Settings?.CollectTimeEnd).format("HH:mm")}` : "-"
        },
        {
            title: 'Лимиты',
            dataIndex: ["Settings", "DayLimit"],
            // render: (value) => value.map((el: string) => <span className={"limits"}>{el}<br/></span>),
            render: (value) => <span className={"limits"}>
                Сутки/{value?.toString()}<br/>
                Неделя/{(value * 7)?.toString()}<br/>
            </span>
        },
        {
            title: 'Кол-во за период',
            render: (value, record) => record.Visits?.length | 0
        },
        {
            title: 'Выгрузка',
            render: (value, record, index) => <a className={"downloadBtn"} download
                                                 href={`${api}/trackers/${record.Id}/visits?csv=true`}>Скачать <DownloadSVG/></a>
        },
        {
            title: 'Интеграция',
            dataIndex: ["Settings", "External"],
            render: (value, record) => <span className={"integrationField"}><span>{record.Settings?.External !== "" ?
                <StatusCircleSVG/> :
                <StatusCircleSVG className={"red"}/>}
                <EditPenSVG className={"editSvg"} onClick={() => {
                    dispatch(changeCurrentTrackerId(record.Id))
                    dispatch(changeNewProjectAction(record))
                    History.push("/project/new?stage=edit")
                }}/>
            </span><span>{value || "Нет"}</span></span>
        },
        {
            title: 'Настройки',
            render: (value, record) => <span style={{cursor: "pointer"}} onClick={() => {
                dispatch(deleteTrackerAction(record.Id))
                // dispatch(changeCurrentTrackerId(record.Id))
                // dispatch(changeNewProjectAction(record))
                // History.push("/project/new?stage=edit")
            }}>Удалить</span>
        }
    ];

    // const data: DataType[] = [
    //     {
    //         key: '1',
    //         other: false,
    //         name: 'ООО Какие люди',
    //         limits: ["Сутки/100", "Неделя/700"],
    //         count: "98",
    //         integration: "Битрикс24",
    //         workingHours: "С 9:00 до 21:00",
    //         status: true
    //     },
    //     {
    //         key: '2',
    //         other: true,
    //         name: 'Конкуренты (100 сайтов)',
    //         limits: ["Сутки/200", "Неделя/1400"],
    //         count: "197",
    //         integration: null,
    //         workingHours: "С 12:00 до 16:00",
    //         status: false
    //     },
    // ];

    return (
        <StyledStyledTable columns={columns} dataSource={data}/>
    );
}

export default StyledTable;