import React, {SVGProps} from 'react';
import styled from "styled-components";

const StyledStopBtnSVG = styled.svg`
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.16);
    border-radius: 6px;
`;

function StopBtnSVG(props: SVGProps<SVGSVGElement>) {
    return (
        <StyledStopBtnSVG {...props} width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" width="44" height="44" rx="6" fill="white"/>
            <rect x="14.5" y="14" width="16" height="16" rx="2" fill="#AFAFAF"/>
        </StyledStopBtnSVG>

    );
}

export default StopBtnSVG;