import styled, {css} from "styled-components";
import {InputNumber} from "antd";

export const StyledNumberInputBase = css`
    width: 100%;
    font-size: 20px;
    border-color: ${({theme}) => theme.gray};
    padding: 15px;
    line-height: 26px;
`;
export const StyledNumberInput = styled(InputNumber)`
    ${StyledNumberInputBase};
`;
