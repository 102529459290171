import React from 'react';
import styled from "styled-components";
import {Space, Tooltip} from "antd";
import {StyledButton} from "../components/styled/StyledButton";
import Info2SVG from "../images/Info2SVG";

const IntegrationsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    padding-bottom: 120px;
`;

const IntegrationBlock = styled.div`
    display: flex;
    background: ${({theme}) => theme.lightBlue};
    border-radius: 14px;
    flex-direction: column;
    padding: 30px 20px;
    max-width: 530px;
    width: 100%;

    .title {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 40px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-space-item {
        width: 100%;
    }

    ${StyledButton} {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: .2rem;
        width: 100%;
    }
`;

const StyledIntegrationsPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 40px;

    h1 {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 40px;
    }

    h2 {
        font-weight: 600;
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
    }
`;

function IntegrationsPage() {
    return (
        <StyledIntegrationsPage>
            <h1>Интеграции с СRM</h1>
            <IntegrationsWrap>
                <IntegrationBlock>
                <span className="title">
                    Google Таблицы
                    <Tooltip title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."}>
                        <Info2SVG/>
                    </Tooltip>
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Битрикс 24
                    <Tooltip title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."}>
                        <Info2SVG/>
                    </Tooltip>
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    AmoCRM
                    <Tooltip title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."}>
                        <Info2SVG/>
                    </Tooltip>
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Мегаплан
                    <Tooltip title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."}>
                        <Info2SVG/>
                    </Tooltip>
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Свой Webhook
                    <Tooltip title={"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor."}>
                        <Info2SVG/>
                    </Tooltip>
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
            </IntegrationsWrap>
            <h2>Другие сервисы</h2>
            <IntegrationsWrap>
                <IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                    <Space size={20}>
                        <StyledButton>Настроить</StyledButton>
                        <StyledButton>Подробнее</StyledButton>
                    </Space>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Квизы (наш)
                </span>
                    <Space size={20}>
                        <StyledButton>Настроить</StyledButton>
                        <StyledButton>Подробнее</StyledButton>
                    </Space>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Что-то ещё1
                </span>
                    <Space size={20}>
                        <StyledButton>Настроить</StyledButton>
                        <StyledButton>Подробнее</StyledButton>
                    </Space>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Что-то ещё2
                </span>
                    <Space size={20}>
                        <StyledButton>Настроить</StyledButton>
                        <StyledButton>Подробнее</StyledButton>
                    </Space>
                </IntegrationBlock>
            </IntegrationsWrap>
        </StyledIntegrationsPage>
    );
}

export default IntegrationsPage;