import React, {useEffect} from 'react';
import styled, {ThemeProvider} from "styled-components";
import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import AuthPage from "./pages/AuthPage";
import {colors} from "./colors";
import DashboardLayout from "./layouts/DashboardLayout";
import NewProjectPage from "./pages/NewProjectPage";
import CurrentProjectsPage from "./pages/CurrentProjectsPage";
import CheckLayout from "./layouts/CheckLayout";
import SearchPage from "./pages/SearchPage";
import History from "./utils/history";
import {useAppDispatch, useAppSelector} from "./redux/hooks";
import {getUserAction} from "./redux/pageSlice";
import TariffsPage from "./pages/TariffsPage";
import IntegrationsPage from "./pages/IntegrationsPage";
import DataBasePage from "./pages/DataBasePage";
import AccountPage from "./pages/AccountPage";
import ExitPage from "./pages/ExitPage";
import OurProjectsPage from "./pages/OurProjectsPage";

const StyledApp = styled.div`
    display: flex;
    width: 100%;
`;

function App() {
    const user = useAppSelector((state) => state.page.user);
    const loading = useAppSelector((state) => state.page.loading);


    const theme_colors = colors;

    const location = useLocation();

    History.navigate = useNavigate();
    History.location = location;
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getUserAction())
    }, []);

    return (
        <StyledApp>
            <ThemeProvider theme={theme_colors}>
                <Routes>
                    {loading ? <Route path={"*"} element={
                        <>
                            Loading
                        </>
                    }></Route> : user?.Login ? <>
                        <Route path={"/project/new"} element={<DashboardLayout>
                            <NewProjectPage/>
                        </DashboardLayout>}/>
                        <Route path={"/"} element={<DashboardLayout>
                            <CurrentProjectsPage/>
                        </DashboardLayout>}/>
                        <Route path={"/tariffs"} element={<DashboardLayout>
                            <TariffsPage/>
                        </DashboardLayout>}/>
                        <Route path={"/integrations"} element={<DashboardLayout>
                            <IntegrationsPage/>
                        </DashboardLayout>}/>
                        <Route path={"/database"} element={<DashboardLayout>
                            <DataBasePage/>
                        </DashboardLayout>}/>
                        <Route path={"/account"} element={<DashboardLayout>
                            <AccountPage/>
                        </DashboardLayout>}/>
                        <Route path={"/exit"} element={<DashboardLayout>
                            <ExitPage/>
                        </DashboardLayout>}/>
                        <Route path={"/projects"} element={<DashboardLayout>
                            <OurProjectsPage/>
                        </DashboardLayout>}/>
                    </> : <>
                        <Route path={"*"} element={<MainLayout>
                            <AuthPage/>
                        </MainLayout>}/>
                    </>}
                </Routes>
            </ThemeProvider>
        </StyledApp>
    );
}

export default App;
