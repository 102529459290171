import styled from "styled-components";
import {DatePicker, TimePicker} from "antd";

export const StyledTimePicker = styled(TimePicker)`
    font-size: 20px;
    border-color: ${({theme}) => theme.gray};
    padding: 5px;
    line-height: 26px;
    width: 100px;

    .ant-picker-input {
        input {
            font-size: 20px;
        }
    }
`;
