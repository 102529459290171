import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import axios from "axios";
import {api} from "../config";
import {message} from "antd";
import History from "../utils/history";
import {NewTrackerType, PlanType, TrackerType, UserType} from "../utils/types";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import {RootState} from "./store";

dayjs.extend(customParseFormat)
type PageStateType = {
    user: UserType | null,
    loading: boolean,
    dateFilter: any,
    rangePickerValue: any,
    trackers: TrackerType[],
    plans: PlanType[],
    currentTrackerId: number | undefined,
    new_project: NewTrackerType,
    trackerStatuses: { [key: number]: { Online: boolean } }
}
const initialState: PageStateType = {
    user: null,
    dateFilter: null,
    plans: [],
    rangePickerValue: undefined,
    trackerStatuses: [],
    currentTrackerId: undefined,
    loading: true,
    trackers: [],
    new_project: {
        Name: "",
        Domains: [],
        Type: "regular",
        Settings: {
            DayLimit: 5,
            External: "",
            DelayPopupSec: 5,
            Geo: [],
            CollectTimeStart: dayjs('10:00', "HH:mm"),
            CollectTimeEnd: dayjs('12:00', "HH:mm")
        }
    }
}

// export const attachAgentConnectionsAction = createAsyncThunk(
//     'page/attachAgentConnectionsAction',
//     async ({agentId, toDelete, toAdd}: {
//         agentId: number,
//         toDelete: string[],
//         toAdd: string[]
//     }, {dispatch, rejectWithValue}) => {
//
//         const requests: any = [];
//
//         requests.push(...toDelete.map(async el => await axios.delete(`${api}/agents/${agentId}/connections/${el}`)))
//         requests.push(...toAdd.map(async el => await axios.put(`${api}/agents/${agentId}/connections/${el}`)))
//
//         // console.log(requests);
//         return await Promise.all(requests).then((el) => {
//             message.success("The list of attached connections has been successfully changed!")
//             dispatch(getGroupAgentsAction())
//             dispatch(getAgentConnectionsAction(agentId))
//         }).catch(({response}) => {
//             message.error(response?.data || "Error")
//             rejectWithValue("rejection")
//             return Promise.reject()
//         })
//     }
// )

export const authAction = createAsyncThunk(
    'page/authAction',
    async ({login, password}: {
        login: string,
        password: string
    }, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/auth?a123=${login}&a321=${password}`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const regAction = createAsyncThunk(
    'page/regAction',
    async ({Login, Phone, Name, Title}: {
        Login: string,
        Phone: string,
        Name: string,
        Title: string
    }, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/reg`, {
            Login,
            Phone,
            Name,
            Title
        }).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const createTrackerAction = createAsyncThunk(
    'page/createTrackerAction',
    async (project: NewTrackerType, {dispatch, rejectWithValue}) => {
        return await axios.post(`${api}/trackers`, project).then(async ({data}) => {
            await dispatch(getTrackersAction({}))
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const editTrackerAction = createAsyncThunk(
    'page/editTrackerAction',
    async (project: NewTrackerType, {dispatch, getState, rejectWithValue}) => {
        const {
            page: {
                currentTrackerId
            }
        } = getState() as RootState;

        if (currentTrackerId !== undefined)
            return await axios.post(`${api}/trackers/${currentTrackerId}`, project).then(async ({data}) => {
                await dispatch(getTrackersAction({}))
                return data
            }).catch(({response}) => {
                return rejectWithValue(response?.data || "Error")
            })
    }
)
export const changeTrackerStateAction = createAsyncThunk(
    'page/changeTrackerStateAction',
    async ({Id, new_state}: { Id: number, new_state: "enable" | "disable" }, {dispatch, getState, rejectWithValue}) => {
        const {
            page: {
                dateFilter,
                rangePickerValue
            }
        } = getState() as RootState;

        return await axios.post(`${api}/trackers/${Id}/state/${new_state}`).then(async ({data}) => {
            if (dateFilter !== null) {
                dispatch(getTrackersAction({
                    params: {
                        start: dateFilter[0]?.format("YYYY-MM-DD"),
                        end: dateFilter[1]?.format("YYYY-MM-DD")
                    }
                }))
            } else if (rangePickerValue !== undefined) {
                dispatch(getTrackersAction({
                    params: {
                        start: rangePickerValue[0]?.format("YYYY-MM-DD"),
                        end: rangePickerValue[1]?.format("YYYY-MM-DD")
                    }
                }))
            }

            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const getUserAction = createAsyncThunk(
    'page/getUserAction',
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/user`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const getTrackerStatusAction = createAsyncThunk(
    'page/getTrackerStatusAction',
    async (_, {dispatch, getState, rejectWithValue,}) => {
        const {
            page: {
                currentTrackerId
            }
        } = getState() as RootState;
        if (currentTrackerId !== undefined)
            return await axios(`${api}/trackers/${currentTrackerId}/status`).then(({data}) => {
                return [currentTrackerId, data]
            }).catch(({response}) => {
                return rejectWithValue(response?.data || "Error")
            })
    }
)
export const getTrackersAction = createAsyncThunk(
    'page/getTrackersAction',
    async ({params}: { params?: any }, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/trackers`, {
            params: {
                visits: true,
                // count: 100
                ...params
            }
        }).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const getPlansAction = createAsyncThunk(
    'page/getPlansAction',
    async (_, {dispatch, rejectWithValue}) => {
        return await axios(`${api}/plans`).then(({data}) => {
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const deleteTrackerAction = createAsyncThunk(
    'page/deleteTrackerAction',
    async (Id: any, {dispatch, getState, rejectWithValue}) => {
        const {
            page: {
                dateFilter,
                rangePickerValue
            }
        } = getState() as RootState;

        return await axios.delete(`${api}/trackers/${Id}`).then(({data}) => {
            if (dateFilter !== null) {
                dispatch(getTrackersAction({
                    params: {
                        start: dateFilter[0]?.format("YYYY-MM-DD"),
                        end: dateFilter[1]?.format("YYYY-MM-DD")
                    }
                }))
            } else if (rangePickerValue !== undefined) {
                dispatch(getTrackersAction({
                    params: {
                        start: rangePickerValue[0]?.format("YYYY-MM-DD"),
                        end: rangePickerValue[1]?.format("YYYY-MM-DD")
                    }
                }))
            }
            return data
        }).catch(({response}) => {
            return rejectWithValue(response?.data || "Error")
        })
    }
)
export const pageSlice = createSlice({
    name: "page",
    initialState,
    reducers: {
        changeNewProjectAction(state, action: PayloadAction<NewTrackerType>) {
            state.new_project = action.payload
        },
        changeCurrentTrackerId(state, action: PayloadAction<number | undefined>) {
            state.currentTrackerId = action.payload
        },
        changeDateFilterAction(state, action: PayloadAction<any>) {
            state.dateFilter = action.payload
        },
        changeRangePickerValueAction(state, action: PayloadAction<any>) {
            state.rangePickerValue = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(authAction.fulfilled, (state, action: any) => {
            state.user = action.payload
            History.push("/")
        })
        builder.addCase(getUserAction.fulfilled, (state, action: any) => {
            state.user = action.payload
            state.loading = false
        })
        builder.addCase(getTrackersAction.fulfilled, (state, action: any) => {
            state.trackers = action.payload
        })
        builder.addCase(getPlansAction.fulfilled, (state, action: any) => {
            state.plans = action.payload
        })
        builder.addCase(getTrackerStatusAction.fulfilled, (state, action: any) => {
            state.trackerStatuses[action.payload[0]] = action.payload[1]
        })
        builder.addCase(createTrackerAction.fulfilled, (state, action: any) => {
            state.currentTrackerId = action.payload.Id
        })
        builder.addCase(regAction.fulfilled, (state, action: any) => {
            // state.user = action.payload
            // message.success("На вашу почту отправлен пароль для авторизации!")
        })
        builder.addCase(authAction.rejected, (state, action: any) => {
            message.error(action.payload || "Error")
        })
        builder.addCase(getUserAction.rejected, (state, action: any) => {
            // message.error(action.payload || "Error")
            state.loading = false
        })
        builder.addCase(regAction.rejected, (state, action: any) => {
            message.error(action.payload || "Error")
        })
        builder.addCase(createTrackerAction.rejected, (state, action: any) => {
            message.error(action.payload || "Error")
        })
        builder.addCase(getPlansAction.rejected, (state, action: any) => {
            message.error(action.payload || "Error")
        })
    }
})
export const {
    changeRangePickerValueAction,
    changeDateFilterAction,
    changeNewProjectAction,
    changeCurrentTrackerId
} = pageSlice.actions
export default pageSlice.reducer