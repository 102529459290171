import React from 'react';
import styled from "styled-components";
import {Space, Tooltip} from "antd";
import {StyledButton} from "../components/styled/StyledButton";
import Info2SVG from "../images/Info2SVG";

const IntegrationsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    padding-bottom: 120px;
`;

const IntegrationBlock = styled.div`
    display: flex;
    background: ${({theme}) => theme.lightBlue};
    border-radius: 14px;
    flex-direction: column;
    padding: 30px 20px;
    max-width: 530px;
    width: 100%;

    .title {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .description {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.gray};
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-space-item {
        width: 100%;
    }

    ${StyledButton} {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: .2rem;
        width: 100%;
    }
`;

const StyledDataBasePage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px 40px;

    h1 {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 42px;
        line-height: 42px;
        margin-bottom: 20px;
    }

    h2 {
        font-weight: 600;
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 42px;
        margin-bottom: 40px;
    }

    h3 {
        font-weight: 500;
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.gray};
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 20px;
    }
`;

function DataBasePage() {
    return (
        <StyledDataBasePage>
            <h1>База знаний</h1>
            <h3>Начало работы в сервисе</h3>
            <IntegrationsWrap>
                <IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                    <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                    <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                    <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                    <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
            </IntegrationsWrap>
            <h2>Рабочие связки для лидогенерации</h2>
            <IntegrationsWrap>
                <IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                    <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock><IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                <StyledButton>Настроить</StyledButton>
            </IntegrationBlock><IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                <StyledButton>Настроить</StyledButton>
            </IntegrationBlock><IntegrationBlock>
                <span className="title">
                    Робот звонарь (наш)
                </span>
                <span className="description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                </span>
                <StyledButton>Настроить</StyledButton>
            </IntegrationBlock>
            </IntegrationsWrap>
        </StyledDataBasePage>
    );
}

export default DataBasePage;