export const colors = {
    blue: "#0F09FF",
    darkBlue: "#0500D0",
    black: "#1B1B1B",
    gray: "#B9B9B9",
    gray2: "#AFAFAF",
    gray3: "#8B8B8B",
    lightBlue: "#F1F4FF",
    lightBlue2: "#00A3FF",
    red: "#FF0000",
    orange: "#FFD600",
    white0: "#FFFFFF",
    white: "#FDFDFF"
}