import React from 'react';
import styled from "styled-components";
import Header from "../components/Header";
import LeftMenu from "../components/LeftMenu";

const ContentWrap = styled.div`
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    overflow: hidden;
`;
const Content = styled.div`
    display: flex;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
        background: #F3F3F3;
        border-radius: 6px;
        width: 26px;
    }

    &::-webkit-scrollbar-thumb {
        background: #afafaf;
        border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background: #afafaf;
        border-radius: 6px;

        &:hover {
            background: #737373;
        }

        &:active {
            background: #7c7575;
        }
    }
`;
const StyledDashboardLayout = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;

    .desktop {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    span.mobile {
        display: none;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 32px;
        font-family: "Suisse Intl", Arial, sans-serif;
        font-weight: 600;
        text-align: center;
        padding: 40px;
        color: ${({theme}) => theme.black};
        background: ${({theme}) => theme.lightBlue};
    }

    @media (max-width: 1200px) {
        span.mobile {
            display: flex;
        }

        .desktop {
            display: none;
        }
    }
`;

type LayoutProps = {
    children: React.ReactNode
}

function DashboardLayout({children}: LayoutProps) {
    return (
        <StyledDashboardLayout>
            <div className={"desktop"}>
                <Header/>
                <ContentWrap>
                    <LeftMenu/>
                    <Content>
                        {children}
                    </Content>
                </ContentWrap>
            </div>
            <span className="mobile">
                Мобильная версия сайта временно недоступна!
            </span>
        </StyledDashboardLayout>
    );
}

export default DashboardLayout;