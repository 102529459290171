import React from 'react';
import styled from "styled-components";
import {StyledButton} from "../components/styled/StyledButton";
import blueBackground from "../images/blueBackground.png";
import lightBlueBackground from "../images/backgroundLighBlue.png";

const Blocks = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    grid-template-areas: 
    "top top"
    ". .";

    .top {
        grid-area: top;
    }
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    padding: 30px 20px;
    background: ${({theme}) => theme.lightBlue};

    ${StyledButton} {
        font-size: 16px;
        padding: 13px 172px;
        letter-spacing: .2rem;
        line-height: 20px;
    }

    &.inverted {
        background: url(${blueBackground}) no-repeat center;
        background-size: cover;

        h1, p, ${StyledButton} {
            color: ${({theme}) => theme.white0};
        }

        ${StyledButton} {
            color: ${({theme}) => theme.black} !important;
            background: ${({theme}) => theme.white0} !important;
            border-color: ${({theme}) => theme.white0} !important;

            &:hover {
                box-shadow: 0 8px 26px 0 #00000080;
            }
        }
    }

    &.lightBlue {
        background: url(${lightBlueBackground}) no-repeat center;
        background-size: cover;

        p {
            color: ${({theme}) => theme.black};
        }
    }

    h1 {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    p {
        font-weight: 500;
        font-size: 18px;
        color: ${({theme}) => theme.gray};
    }
`;

const Actions = styled.div`

`;

const StyledOurProjectsPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 40px;
`;

function OurProjectsPage() {
    return (
        <StyledOurProjectsPage>
            <Blocks>
                <Block className={"top inverted"}>
                    <h1>Команда маркетологов для B2B компаний с опытом работы 5+ лет</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <Actions>
                        <StyledButton>Подробнее</StyledButton>
                    </Actions>
                </Block>
                <Block>
                    <h1>Робот автообзвона</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <Actions>
                        <StyledButton>Подробнее</StyledButton>
                    </Actions>
                </Block>
                <Block className={"lightBlue"}>
                    <h1>Поиск информации ЛПР</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    <Actions>
                        <StyledButton>Подробнее</StyledButton>
                    </Actions>
                </Block>
            </Blocks>
        </StyledOurProjectsPage>
    );
}

export default OurProjectsPage;