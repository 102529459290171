import React, {useEffect, useMemo, useState} from 'react';
import styled from "styled-components";
import {Button, DatePicker, Select, Space} from "antd";
import {StyledSelect} from "../components/styled/StyledSelect";
import CircleSVG from "../images/CircleSVG";
import {colors} from "../colors";
import StyledTable from "../components/StyledTable";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {
    changeDateFilterAction,
    changeNewProjectAction,
    changeRangePickerValueAction,
    getTrackersAction
} from "../redux/pageSlice";
import dayjs from "dayjs";
import updateLocale from 'dayjs/plugin/updateLocale'
import * as _ from "lodash/fp";
import {uiScale} from "../config";

const PeriodWrap = styled.div`
    display: flex;
    min-width: 354px;
    gap: 20px;
    align-items: center;
    border-radius: 4px;
    background: ${({theme}) => theme.lightBlue};

    .rangePicker {
        display: flex;
        align-items: center;
        color: ${({theme}) => theme.black};
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;

        .ant-picker-range-separator {
            color: ${({theme}) => theme.black};
        }
    }

    .ant-picker {
        padding: 12px 20px 12px 5px;
    }

    *::placeholder, .ant-picker-input input, .ant-select-selection-item {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: ${({theme}) => theme.black};
    }


    .separator {
        font-size: 16px;
        font-weight: 500;
        color: ${({theme}) => theme.blue};
    }
`;
const Top = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    gap: 44px;

    .changeTableBtn {
        border-radius: 4px;
        background: ${({theme}) => theme.lightBlue} !important;
        font-size: 16px;
        color: ${({theme}) => theme.black};
        font-weight: 500;
        line-height: 20px;
        border: none;
        padding: 12px 40px;
        height: auto;
        margin-left: auto;
    }

    .legend {

        font-size: 12px;

        span {
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            color: ${({theme}) => theme.gray2};

            svg {
                height: 12px;
                width: 12px;
            }
        }
    }

    .ant-select {
        height: auto;

        .ant-select-selector {
            font-size: 16px;
            padding: 11px 20px;
            font-weight: 500;
            line-height: 20px;

            &::after {
                line-height: 20px;
            }

            .ant-select-selection-item {
                line-height: 20px;
                font-weight: 500;
            }
        }
    }
`;

const Content = styled.div`
    display: flex;
    width: 100%;
`;

const StyledCurrentProjectsPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    padding: calc(40px * ${uiScale});
`;

function CurrentProjectsPage() {
    const trackers = useAppSelector((state) => state.page.trackers);
    const rangePickerValue = useAppSelector((state) => state.page.rangePickerValue);
    const dateFilter = useAppSelector((state) => state.page.dateFilter);
    const dispatch = useAppDispatch();
    const {RangePicker} = DatePicker;

    const [activeFilter, setActiveFilter] = useState<boolean | null>(null)

    useEffect(() => {
        dispatch(getTrackersAction({}))
    }, []);


    const [filterValue, setFilterValue] = useState<"custom" | "today" | "yesterday" | "last3Days" | "thisWeek" | "thisMonth" | "last6Months" | "inAYear">("today")

    // const dateFilter: any = useMemo(() => {
    //     const values: any = {
    //         today: [dayjs(), dayjs()],
    //         yesterday: [dayjs().add(-1, "days"), dayjs().add(-1, "days")],
    //         last3Days: [dayjs().add(-2, "days"), dayjs()],
    //         thisWeek: [dayjs().startOf("week"), dayjs()],
    //         thisMonth: [dayjs().startOf("month"), dayjs()],
    //         last6Months: [dayjs().startOf("month").add(-5, "month"), dayjs()],
    //         inAYear: [dayjs().startOf("year"), dayjs()]
    //     }
    //     console.log(values[filterValue]);
    //     if (filterValue !== "custom")
    //         return values[filterValue]
    //     else
    //         return null
    // }, [filterValue]);
    //
    useEffect(() => {
        const values: any = {
            today: [dayjs(), dayjs()],
            yesterday: [dayjs().add(-1, "days"), dayjs().add(-1, "days")],
            last3Days: [dayjs().add(-2, "days"), dayjs()],
            thisWeek: [dayjs().startOf("week"), dayjs()],
            thisMonth: [dayjs().startOf("month"), dayjs()],
            last6Months: [dayjs().startOf("month").add(-5, "month"), dayjs()],
            inAYear: [dayjs().startOf("year"), dayjs()]
        }
        if (filterValue !== "custom")
            dispatch(changeDateFilterAction(values[filterValue]))
        else
            dispatch(changeDateFilterAction(null))
    }, [filterValue]);

    useEffect(() => {
        if (dateFilter !== null) {
            dispatch(getTrackersAction({
                params: {
                    start: dateFilter[0]?.format("YYYY-MM-DD"),
                    end: dateFilter[1]?.format("YYYY-MM-DD")
                }
            }))
        } else if (rangePickerValue !== undefined) {
            dispatch(getTrackersAction({
                params: {
                    start: rangePickerValue[0]?.format("YYYY-MM-DD"),
                    end: rangePickerValue[1]?.format("YYYY-MM-DD")
                }
            }))
        }
    }, [dateFilter, rangePickerValue]);

    return (
        <StyledCurrentProjectsPage>
            <Top>
                <PeriodWrap>
                    <span className={"rangePicker"}>с <RangePicker
                        onChange={(value) => dispatch(changeRangePickerValueAction(value))}
                        format={"DD-MM-YYYY"}
                        disabled={dateFilter !== null}
                        value={dateFilter ?? rangePickerValue}
                        separator={"по"}
                        suffixIcon={null}
                        variant={"borderless"}/>
                    </span>
                    <span className={"separator"}>/</span>
                    <Select style={{width: 260}} variant={"borderless"}
                            onChange={(value) => {
                                setFilterValue(value)
                            }}
                            value={filterValue} options={[{
                        label: "Указать свой период",
                        value: "custom"
                    }, {
                        label: "Сегодня",
                        value: "today"
                    }, {
                        label: "Вчера",
                        value: "yesterday"
                    }, {
                        label: "Последние 3 дня",
                        value: "last3Days"
                    }, {
                        label: "Эта неделя",
                        value: "thisWeek"
                    }, {
                        label: "Этот месяц",
                        value: "thisMonth"
                    }, {
                        label: "Последние 6 месяцев",
                        value: "last6Months"
                    }, {
                        label: "За год",
                        value: "inAYear"
                    }]}/>
                </PeriodWrap>

                <Select style={{minWidth: 140}} value={activeFilter} onChange={(value) => setActiveFilter(value)}
                        options={[{
                            label: "Все",
                            value: null
                        }, {
                            label: "Активные",
                            value: true
                        }, {
                            label: "На паузе",
                            value: false
                        }]}/>
                <Space direction="vertical" size={5} className={"legend"}>
                    <span><CircleSVG color={colors.orange}/> Свой сайт</span>
                    <span><CircleSVG color={colors.lightBlue2}/> Другие сайты</span>
                </Space>
                <Button type="text" disabled className={"changeTableBtn"}>Изменить вид таблицы</Button>
            </Top>
            <Content>
                <StyledTable dateFilter={dateFilter} rangePickerValue={rangePickerValue}
                             data={trackers?.filter(tracker => {
                                 if (activeFilter === true) {
                                     return tracker?.Enabled === true
                                 }
                                 if (activeFilter === false) {
                                     return tracker?.Enabled === false
                                 }

                                 return true
                             })}/>
            </Content>
        </StyledCurrentProjectsPage>
    );
}

export default CurrentProjectsPage;