import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {StyledButton} from "../components/styled/StyledButton";
import {uiScale} from "../config";
import {useAppDispatch, useAppSelector} from "../redux/hooks";
import {getPlansAction} from "../redux/pageSlice";
import {declension} from "../utils/Declension";
import {message} from "antd";
import StyledTable from "../components/StyledTable";
import {StyledSelect} from "../components/styled/StyledSelect";
import StyledDocumentsTable from "../components/StyledDocumentsTable";
import StyledHistoryTable from "../components/StyledHistoryTable";

const StyledTariffs = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-select {
        background: ${({theme}) => theme.lightBlue};
        border-radius: 4px;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        width: fit-content;
    }

    ${StyledButton} {
        padding: calc(11px * ${uiScale}) calc(20px * ${uiScale});
        font-size: calc(16px * ${uiScale});
        line-height: calc(20px * ${uiScale});
    }
`;

const Top = styled.div`
    display: flex;
    border-bottom: calc(2px * ${uiScale}) solid ${({theme}) => theme.lightBlue};
    padding: calc(20px * ${uiScale}) calc(40px * ${uiScale});
    gap: calc(20px * ${uiScale});

    ${StyledButton} {
        border-radius: calc(4px * ${uiScale});
        text-transform: none;
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: calc(40px * ${uiScale});

    h1 {
        font-size: calc(42px * ${uiScale});
        line-height: calc(42px * ${uiScale});
        font-weight: 600;
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        margin-bottom: calc(20px * ${uiScale});

        span {
            color: ${({theme}) => theme.blue};
        }
    }

    h2 {
        font-size: calc(18px * ${uiScale});
        font-weight: 500;
        line-height: calc(24px * ${uiScale});
        color: ${({theme}) => theme.gray};
        margin-bottom: calc(20px * ${uiScale});

        span {
            color: ${({theme}) => theme.blue};
        }
    }
`;
const Tariffs = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: calc(20px * ${uiScale});
`;

const Tariff = styled.div`
    display: flex;
    flex-direction: column;
    gap: calc(20px * ${uiScale});
    background: ${({theme}) => theme.lightBlue};
    border-radius: calc(14px * ${uiScale});
    padding: calc(30px * ${uiScale}) calc(20px * ${uiScale});

    span {
        display: flex;
    }

    .title {
        display: inline;
        line-height: calc(32px * ${uiScale});
        font-size: calc(32px * ${uiScale});
        font-weight: 600;
        font-family: "Suisse Intl", Arial, sans-serif;

        span {
            font-family: "Suisse Intl", Arial, sans-serif;
            display: inline;
            color: ${({theme}) => theme.blue};
        }
    }

    .actions {
        gap: calc(20px * ${uiScale});

    }

    .bonus {
        font-size: calc(18px * ${uiScale});
        line-height: calc(24px * ${uiScale});
        font-weight: 500;
        color: ${({theme}) => theme.gray};
    }

`;

function TariffsPage() {
    const [currentTab, setCurrentTab] = useState<"payment" | "history" | "documents">("payment")
    const plans = useAppSelector((state) => state.page.plans);
    const dispatch = useAppDispatch();
    const messageMockup = () => {
        message.warning(<span>Свяжитесь с нами по электронной почте <a
            href="mailto:vaizmanai@gmail.com">vaizmanai@gmail.com</a></span>)
    }

    useEffect(() => {
        dispatch(getPlansAction())
    }, []);

    return (
        <StyledTariffs>
            <Top>
                <StyledButton onClick={() => setCurrentTab("payment")}
                              className={currentTab === "payment" ? "" : "gray"}>Оплатить тариф</StyledButton>
                <StyledButton onClick={() => setCurrentTab("history")}
                              className={currentTab === "history" ? "" : "gray"}>История оплат</StyledButton>
                <StyledButton onClick={() => setCurrentTab("documents")} style={{marginLeft: "auto"}}
                              className={currentTab === "documents" ? "" : "gray"}>Закрывающие документы</StyledButton>
            </Top>
            <Content>
                {currentTab === "payment" ? <>
                    <h1>Тарифы</h1>
                    <h2>Стоимость за 1 контакт - <span>12.5 ₽</span></h2>
                    <Tariffs>
                        {plans?.map(plan => <Tariff>
                        <span className="title">
                            <span>{plan.Value} </span> {declension((plan.Value || 0), ['контакт', 'контакта', 'контактов'])}
                        </span>
                            <span className="actions">
                            <StyledButton onClick={messageMockup}>Выставить счёт</StyledButton>
                            <StyledButton onClick={messageMockup}>оплата по карте</StyledButton>
                        </span>
                            <span className="bonus">
                            {plan.Description}
                        </span>
                        </Tariff>)}
                    </Tariffs>
                </> : currentTab === "history" ? <>
                    <StyledHistoryTable/>
                </> : <>
                    <h1>Доступен <span>электронный документооборот (ЭДО)</span></h1>
                    <StyledSelect variant={"borderless"} value={"test"} options={[{value: 'test', label: 'Все документы'}]}/>
                    <StyledDocumentsTable/>
                </>}
            </Content>
        </StyledTariffs>
    );
}

export default TariffsPage;