import styled from "styled-components";
import {Button} from "antd";
import {uiScale} from "../../config";

export const StyledButton = styled(Button)<{ disabled?: boolean }>`
    font-size: calc(23px * ${uiScale});
    text-transform: uppercase;
    background: ${({theme, disabled}) => disabled ? theme.gray2 : theme.blue} !important;
    color: ${({theme}) => theme.white} !important;
    padding: calc(10px * ${uiScale});
    height: auto;
    border-radius: calc(8px * ${uiScale});
    border-color: ${({theme, disabled}) => disabled ? theme.gray2 : theme.blue} !important;
    font-weight: 500;

    &:hover {
        box-shadow: ${({disabled}) => disabled ? 'none' : `0 8px 26px 0 #0600FF80`};
    }

    &.white {
        border-color: ${({theme, disabled}) => disabled ? theme.gray2 : theme.white0} !important;
        background: ${({theme, disabled}) => disabled ? theme.gray2 : theme.white0} !important;
        box-shadow: ${({disabled}) => disabled ? 'none' : `0 0 16px 0 #00000029`};

        &:hover {
            box-shadow: ${({disabled}) => disabled ? 'none' : `0 8px 26px 0 #00000029`};
        }
    }

    &.gray {
        border-color: ${({theme, disabled}) => disabled ? theme.gray2 : theme.gray2} !important;
        background: ${({theme, disabled}) => disabled ? theme.gray2 : theme.gray2} !important;
        box-shadow: ${({disabled}) => disabled ? 'none' : `none`};

        &:hover {
            box-shadow: ${({disabled}) => disabled ? 'none' : `0 8px 26px 0 #00000029`};
        }
    }

`;