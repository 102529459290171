import styled, {css} from "styled-components";
import {Input} from "antd";

export const StyledInputBase = css`
    font-size: 20px;
    border-color: ${({theme}) => theme.gray};
    padding: 15px;
    line-height: 26px;
`;
export const StyledInput = styled(Input)`
    ${StyledInputBase};
`;

const Password = styled(Input.Password)`
    ${StyledInputBase};
`;

StyledInput.Password = Password;