import styled, {css} from "styled-components";
import {Checkbox, Select} from "antd";

export const StyledCheckboxBase = css`
    font-size: 14px;
    line-height: 18px;
    color: ${({theme}) => theme.gray3};
    font-weight: 500;
`;
export const StyledCheckbox = styled(Checkbox)`
    ${StyledCheckboxBase};
`;
