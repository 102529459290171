import React from 'react';
import {Table, TableProps, Tooltip} from "antd";
import styled from "styled-components";
import StopBtnSVG from "../images/StopBtnSVG";
import StartBtnSVG from "../images/StartBtnSVG";
import DownloadSVG from "../images/DownloadSVG";
import CircleSVG from "../images/CircleSVG";
import {colors} from "../colors";
import EditPenSVG from "../images/EditPenSVG";
import StatusCircleSVG from "../images/StatusCircleSVG";
import {TrackerType} from "../utils/types";
import {api} from "../config";
import {useAppDispatch} from "../redux/hooks";
import {
    changeCurrentTrackerId,
    changeNewProjectAction,
    changeTrackerStateAction,
    deleteTrackerAction
} from "../redux/pageSlice";
import dayjs from "dayjs";
import History from "../utils/history";

const StyledStyledTable = styled(Table)<TableProps>`
    width: 100%;
    font-size: 16px;
    font-weight: 500;

    .ant-table {
        * {
            line-height: 20px;
        }
    }

    .editSvg {
        cursor: pointer;
    }

    .nameField, .integrationField {
        display: flex;
        flex-direction: column;
        gap: 14px;

        span {
            display: flex;
            align-items: center;
            gap: 10px;

            svg {
                width: 16px;
                height: 16px;
            }
        }
    }

    .integrationField {
        color: ${({theme}) => theme.gray2};
    }

    .downloadBtn {
        color: ${({theme}) => theme.blue};
        display: flex;
        align-items: center;
        gap: 10px;
        text-decoration: underline;
    }

    .limits {
        color: ${({theme}) => theme.gray2};
        font-size: 14px;
    }

    .ant-table-thead {
        font-size: 16px;

        .ant-table-cell {
            padding: 15px 10px;
            background: ${({theme}) => theme.blue};
            color: ${({theme}) => theme.white0};

            &:first-child {
                border-start-start-radius: 4px !important;
            }

            &:last-child {
                border-start-end-radius: 4px !important;
            }

            &:before {
                display: none;
            }
        }
    }

    .ant-table-tbody {
        font-size: 16px;

        .ant-table-row {
            .ant-table-cell {
                padding: 15px 10px;
                color: ${({theme}) => theme.black};
                border-right: 2px solid ${({theme}) => theme.lightBlue};
                border-bottom: 2px solid ${({theme}) => theme.lightBlue};

                &:first-child {
                    border-left: 2px solid ${({theme}) => theme.lightBlue};
                }
            }

            &:last-child {
                .ant-table-cell {
                    &:first-child {
                        border-bottom-left-radius: 4px;
                    }

                    &:last-child {
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }
    }
`;

function StyledDocumentsTable({data, dateFilter, rangePickerValue}: any) {
    const dispatch = useAppDispatch();

    const columns: TableProps<TrackerType>['columns'] = [
        {
            title: '№ документа',
            dataIndex: 'Id',
        },
        {
            title: 'Дата',
            dataIndex: 'Date',
        },
        {
            title: 'Тип документа',
            dataIndex: 'Type',
        },
        {
            title: 'Сумма руб.',
            dataIndex: 'Value',
        }
    ];

    return (
        <StyledStyledTable columns={columns} dataSource={data}/>
    );
}

export default StyledDocumentsTable;