import React from 'react';
import styled from "styled-components";
import {uiScale} from "../config";
import NewProjectSVG from "../images/NewProjectSVG";
import ProjectsSVG from "../images/ProjectsSVG";
import FinanceSVG from "../images/FinanceSVG";
import IntegrationsSVG from "../images/IntegrationsSVG";
import DataBaseSVG from "../images/DataBaseSVG";
import AccountSVG from "../images/AccountSVG";
import LogoutSVG from "../images/LogoutSVG";
import WhatsAppSVG from "../images/WhatsAppSVG";
import TelegramSVG from "../images/TelegramSVG";
import {Link, useLocation} from "react-router-dom";

const Socials = styled.div`
    display: flex;
    gap: 10px;
    margin-top: 10px;
`;

const About = styled.div`
    display: flex;
    margin-top: auto;
    flex-direction: column;
    font-weight: 500;
    color: ${({theme}) => theme.gray2};
    padding: calc(15px * ${uiScale});
    font-size: calc(14px * ${uiScale});
    line-height: calc(20px * ${uiScale});

    p {
        margin: 0;

        &:first-child {
            margin-bottom: 10px;
        }
    }

    span {
        color: ${({theme}) => theme.blue};
        margin-top: 20px;

        a {
            text-decoration: none;
            color: ${({theme}) => theme.blue};
        }
    }
`;

const Menu = styled.div`
    display: flex;
    flex-direction: column;

    a {
        text-decoration: none;
    }
`;

const MenuItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: ${({theme}) => theme.gray2};
    font-size: calc(12px * ${uiScale});
    font-weight: 500;
    line-height: calc(15px * ${uiScale});
    padding: 9px 36px;
    gap: 10px;

    &.active {
        color: ${({theme}) => theme.white0};
        background: ${({theme}) => theme.blue};

        svg {
            g rect {
                fill: ${({theme}) => theme.white0};
            }
        }
    }
`;

const StyledLeftMenu = styled.div`
    display: flex;
    flex-direction: column;
    border-right: 1px solid ${({theme}) => theme.lightBlue};
    min-width: calc(161px * ${uiScale});
    max-width: calc(161px * ${uiScale});

    span.separator {
        border-bottom: 2px solid ${({theme}) => theme.lightBlue};
        margin: 10px 0;
    }
`;

function LeftMenu() {
    const location = useLocation();

    return (
        <StyledLeftMenu>
            <Menu>
                <Link to={"/project/new"}>
                    <MenuItem className={location.pathname.includes("/project/new") ? "active" : ""}>
                        <NewProjectSVG/>
                        Новый проект
                    </MenuItem>
                </Link>
                <Link to={"/"}>
                    <MenuItem className={location.pathname === "/" ? "active" : ""}>
                        <ProjectsSVG/>
                        Действующие
                        проекты
                    </MenuItem>
                </Link>
                <span className={"separator"}/>
                <Link to={"/tariffs"}>
                    <MenuItem className={location.pathname === "/tariffs" ? "active" : ""}>
                        <FinanceSVG/>
                        Финансы
                    </MenuItem>
                </Link>
                <Link to={"/integrations"}>
                    <MenuItem className={location.pathname === "/integrations" ? "active" : ""}>
                        <IntegrationsSVG/>
                        Интеграции
                    </MenuItem>
                </Link>
                <Link to={"/database"}>
                    <MenuItem className={location.pathname === "/database" ? "active" : ""}>
                        <DataBaseSVG/>
                        База знаний
                    </MenuItem>
                </Link>
                <span className={"separator"}/>
                <Link to={"/account"}>
                    <MenuItem className={location.pathname === "/account" ? "active" : ""}>
                        <AccountSVG/>
                        Мой аккаунт
                    </MenuItem>
                </Link>
                <Link to={"/exit"}>
                    <MenuItem>
                        <LogoutSVG/>
                        Выход
                    </MenuItem>
                </Link>
            </Menu>
            <About>
                <p>Ваш менеджер:</p>
                <p>Лундин Артем</p>
                <p>Тел: 89995816763</p>
                <Socials>
                    <WhatsAppSVG/>
                    <TelegramSVG/>
                </Socials>
                <span><Link to={"/projects"} >Все наши проекты</Link></span>
            </About>
        </StyledLeftMenu>
    );
}

export default LeftMenu;