import React, {useState} from 'react';
import styled from "styled-components";
import {DatePicker, Form, Input, message, Select} from "antd";
import {StyledButton} from "../components/styled/StyledButton";
import CrownSVG from "../images/CrownSVG";
import CopySVG from "../images/CopySVG";
import StyledClientsTable from "../components/StyledClientsTable";

const Content = styled.div`
    padding: 40px;

    .ant-form {
        max-width: 520px;

        .ant-form-item {
            &:not(:last-child) {
                margin-bottom: 26px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .ant-input-password {
            padding: 14px 12px;

            .ant-input {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    h1 {
        font-family: "Suisse Intl", Arial, sans-serif;
        margin-bottom: 20px;
        font-weight: 600;
        font-size: 32px;
        line-height: 38px;
    }

    ${StyledButton} {
        font-size: 24px;
        line-height: 35px;
        letter-spacing: .2rem;
        padding: 10px 78px;
    }
`;

const Top = styled.div`
    display: flex;
    gap: 20px;
    padding: 20px 40px;
    border-bottom: 2px solid ${({theme}) => theme.lightBlue};

    ${StyledButton} {
        font-size: 16px;
        padding: 14px 20px;
        line-height: 20px;
        font-weight: 500;
        text-transform: capitalize;

        &:last-child {
            background: ${({theme}) => theme.black} !important;
            border-color: ${({theme}) => theme.black} !important;

            &:hover {
                box-shadow: 0 8px 26px 0 #00000080;
            }
        }
    }
`;

const Variants = styled.div`
    display: flex;
    gap: 20px;
    margin-bottom: 180px;
`;

const Variant = styled.div`
    display: flex;
    border-radius: 14px;
    flex: 50%;
    padding: 35px 20px;
    flex-direction: column;
    background: ${({theme}) => theme.lightBlue};

    h3 {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 20px;
    }
`;

const Filters = styled.div`
    display: flex;
    margin-bottom: 40px;
`;

const ClientsWrap = styled.div`
    display: flex;
    flex-direction: column;
`;


const PartnershipsContent = styled.div`
    display: flex;
    flex-direction: column;
    padding: 40px;
`;

const PeriodWrap = styled.div`
    display: flex;
    min-width: 354px;
    gap: 20px;
    align-items: center;
    border-radius: 4px;
    background: ${({theme}) => theme.lightBlue};

    .rangePicker {
        display: flex;
        align-items: center;
        color: ${({theme}) => theme.black};
        font-size: 16px;
        font-weight: 500;
        padding-left: 20px;

        .ant-picker-range-separator {
            color: ${({theme}) => theme.black};
        }
    }

    .ant-picker {
        padding: 12px 20px 12px 5px;
    }

    *::placeholder, .ant-picker-input input, .ant-select-selection-item {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: ${({theme}) => theme.black};
    }


    .separator {
        font-size: 16px;
        font-weight: 500;
        color: ${({theme}) => theme.blue};
    }
`;

const PartnershipPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    .ant-input-affix-wrapper {
        line-height: 26px;
        font-size: 20px;

        svg {
            cursor: pointer;
        }
    }

    h2 {
        font-weight: 500;
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.gray};
        font-size: 18px;
        line-height: 23px;
        margin-bottom: 20px;
    }
`;

const IntegrationBlock = styled.div`
    display: flex;
    background: ${({theme}) => theme.lightBlue};
    border-radius: 14px;
    flex-direction: column;
    padding: 30px 20px;
    flex: 1 1 calc(50% - 10px);

    .title {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.black};
        font-size: 32px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .description {
        font-family: "Suisse Intl", Arial, sans-serif;
        color: ${({theme}) => theme.gray};
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-space-item {
        width: 100%;
    }

    ${StyledButton} {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: .2rem;
        width: 100%;
    }
`;

const IntegrationsWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 100%;
    padding-bottom: 120px;
`;

const StyledAccountPage = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

function AccountPage() {
    const [currentTab, setCurrentTab] = useState<"account" | "partners">("account");

    const referralLink = "expertteam/referral_link0001";
    const {RangePicker} = DatePicker;

    const TopBlock = () => (<Top>
        <StyledButton onClick={() => setCurrentTab("account")}>Безопасность</StyledButton>
        <StyledButton onClick={() => setCurrentTab("partners")}>
            <CrownSVG/>
            Партнёрам
        </StyledButton>
    </Top>);

    return currentTab === "account" ? (<StyledAccountPage>
        {TopBlock()}
        <Content>
            <h1>Изменить пароль для входа</h1>
            <Form>
                <Form.Item>
                    <Input.Password placeholder={"Старый пароль"}/>
                </Form.Item>
                <Form.Item>
                    <Input.Password placeholder={"Новый пароль"}/>
                </Form.Item>
                <Form.Item>
                    <StyledButton>Сменить</StyledButton>
                </Form.Item>
            </Form>
        </Content>
    </StyledAccountPage>) : (<PartnershipPage>
        {TopBlock()}
        <PartnershipsContent>
            <h1>Подключение клиентов</h1>
            <h2>
                Выберете 1 из 2 вариантов для подключения клиентов и получайте вознаграждение в размере 20% со всех
                оплат, пока клиент работает с сервисом
            </h2>
            <Variants>
                <Variant>
                    <h3>1 вариант</h3>
                    <h2>Пригласите своего клиента по реферальной ссылке</h2>
                    <Input
                        value={referralLink}
                        suffix={<CopySVG
                            onClick={() => navigator.clipboard.writeText(referralLink).then(() => {
                                message.success("Реферальная ссылка была успешно скопирована!");
                            })}
                        />}
                    />
                </Variant>
                <Variant>
                    <h3>2 вариант</h3>
                    <h2>
                        Зарегистрируйте самостоятельно вашего клиента в сервисе и сообщите его ID менеджеру по работе с
                        партнерами. Контакты менеджера, в левом нижнем углу
                    </h2>
                </Variant>
            </Variants>
            <h1>Информация для партнёров</h1>
            <IntegrationsWrap>
                <IntegrationBlock>
                    <span className="title">Робот звонарь (наш)</span>
                    <span className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                    </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                    <span className="title">Робот звонарь (наш)</span>
                    <span className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                    </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                    <span className="title">Робот звонарь (наш)</span>
                    <span className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                    </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
                <IntegrationBlock>
                    <span className="title">Робот звонарь (наш)</span>
                    <span className="description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.
                    </span>
                    <StyledButton>Настроить</StyledButton>
                </IntegrationBlock>
            </IntegrationsWrap>
            <h1>Статистика по клиентам</h1>
            <ClientsWrap>
                <Filters>
                    <PeriodWrap>
                    <span className={"rangePicker"}>с <RangePicker
                        format={"DD-MM-YYYY"}
                        separator={"по"}
                        suffixIcon={null}
                        variant={"borderless"}/>
                    </span>
                        <span className={"separator"}>/</span>
                        <Select style={{width: 260}} variant={"borderless"}
                                value={"today"}
                                options={[{
                                    label: "Указать свой период",
                                    value: "custom"
                                }, {
                                    label: "Сегодня",
                                    value: "today"
                                }, {
                                    label: "Вчера",
                                    value: "yesterday"
                                }, {
                                    label: "Последние 3 дня",
                                    value: "last3Days"
                                }, {
                                    label: "Эта неделя",
                                    value: "thisWeek"
                                }, {
                                    label: "Этот месяц",
                                    value: "thisMonth"
                                }, {
                                    label: "Последние 6 месяцев",
                                    value: "last6Months"
                                }, {
                                    label: "За год",
                                    value: "inAYear"
                                }]}/>
                    </PeriodWrap>
                </Filters>
                <StyledClientsTable/>
            </ClientsWrap>
        </PartnershipsContent>
    </PartnershipPage>);
}

export default AccountPage;
