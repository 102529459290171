import React, {useState} from 'react';
import styled from "styled-components";
import {uiScale} from "../config";
import LogoSVG from "../images/LogoSVG";
import BellSVG from "../images/BellSVG";
import {Badge} from "antd";
import {useAppSelector} from "../redux/hooks";

const Logo = styled.div`
    display: flex;
    width: calc(160px * ${uiScale});
    padding: calc(24px * ${uiScale});
`;

const StyledHeader = styled.div`
    display: flex;
    align-items: center;
`;
const Contacts = styled.div`
    display: flex;
    color: ${({theme}) => theme.white0};
    background: ${({theme}) => theme.blue};
    padding: calc(12px * ${uiScale}) calc(16px * ${uiScale});
    font-size: calc(16px * ${uiScale});
    font-weight: 500;
    line-height: calc(20px * ${uiScale});
    border-radius: calc(4px * ${uiScale});
    border: 1px solid ${({theme}) => theme.blue};
    z-index: 1;
`;

const HeaderContent = styled.div`
    display: flex;
    align-items: center;
    padding: calc(18px * ${uiScale}) calc(40px * ${uiScale});
    border-bottom: 1px solid ${({theme}) => theme.lightBlue};
    width: 100%;

    .ant-badge {
        margin-left: 20px;

        sup {
            box-shadow: none;
            background: ${({theme}) => theme.red};
            display: flex;
            height: auto;
            padding: 3px;
            border-radius: 100%;
            font-size: 8px;
            transform: translate(6%, -4%);
            font-weight: 700;

            bdi {
                display: flex;

                span {
                    height: auto !important;
                    font-size: 8px;
                    line-height: 8px;
                }
            }
        }

        &.big {
            sup {
                transform: translate(31%, -13%);
            }
        }
    }

    > span {
        color: ${({theme}) => theme.gray2};
        font-size: calc(14px * ${uiScale});
        line-height: calc(22px * ${uiScale});
        font-weight: 500;
    }
`;

const ContactsWrap = styled.div`
    display: flex;
    align-items: center;
    margin-left: auto;

    > span {
        z-index: 0;
        border: 1px solid ${({theme}) => theme.gray2};
        border-left: none;
        border-radius: calc(4px * ${uiScale});
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        left: -3px;
        display: flex;
        color: ${({theme}) => theme.black};
        padding: calc(12px * ${uiScale}) calc(16px * ${uiScale});
        font-size: calc(16px * ${uiScale});
        font-weight: 500;
        line-height: calc(20px * ${uiScale});
    }
`;

function Header() {

    const [count, setCount] = useState(0)
    const user = useAppSelector((state) => state.page.user);

    return (
        <StyledHeader>
            <Logo>
                <LogoSVG/>
            </Logo>
            <HeaderContent>
                <span>ID аккаунта: {user?.Login}</span>
                <ContactsWrap>
                    <Contacts>
                        Осталось контактов
                    </Contacts>
                    <span>{user?.Balance}</span>
                </ContactsWrap>
                <Badge size={"small"} count={count} className={count > 99 ? "big" : ""}>
                    <BellSVG/>
                </Badge>
            </HeaderContent>
        </StyledHeader>
    );
}

export default Header;