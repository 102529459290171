import styled, {css} from "styled-components";
import {Select, SelectProps} from "antd";

export const StyledSelectBase = css`
    height: auto;

    &.ant-select-multiple {
        .ant-select-selection-placeholder {
            padding: 15px !important;

        }

        .ant-select-selection-overflow-item {
            width: 100%;
        }

        .ant-select-selection-search {
            width: 100% !important;
        }

        .ant-select-selection-search-input {
            min-width: auto;
            height: auto;
        }

    }

    .ant-select-selector {
        font-size: 20px;
        border-color: ${({theme}) => theme.gray} !important;
        padding: 13px !important;
        line-height: 26px;
        height: auto;


    }
`;
export const StyledSelect = styled(Select)<SelectProps>`
    ${StyledSelectBase};
`;
