import React from 'react';
import styled from "styled-components";
import {Space} from "antd";
import {StyledButton} from "../components/styled/StyledButton";
import {api} from "../config";
import {useNavigate} from "react-router-dom";

const StyledExitPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > span {
        max-width: 750px;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 52px;
        border-radius: 18px;
        border: 1px solid ${({theme}) => theme.blue};

        .ant-space {
            .ant-space-item {
                width: 100%;

                ${StyledButton} {
                    width: 100%;
                }
            }
        }
    }

    padding: 40px;

    .ant-form {
        .ant-form-item {
            &:not(:last-child) {
                margin-bottom: 26px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .ant-input-password {
            padding: 14px 12px;

            .ant-input {
                font-size: 20px;
                line-height: 26px;
            }
        }
    }

    h1 {
        font-family: "Suisse Intl", Arial, sans-serif;
        margin-bottom: 36px;
        font-weight: 600;
        font-size: 42px;
        line-height: 42px;
    }
`;

function ExitPage() {
    const navigate = useNavigate();

    return (
        <StyledExitPage>
            <span>
                <h1>Выйти из сервиса?</h1>
                <Space size={26}>
                    <a href={`${api}/exit`}><StyledButton>Да</StyledButton></a>
                    <StyledButton onClick={() => navigate(-1)}>Нет</StyledButton>
                </Space>
            </span>
        </StyledExitPage>
    );
}

export default ExitPage;