import React, {SVGProps} from 'react';

function LogoSVG(props: SVGProps<SVGSVGElement>) {
    return (
        <svg {...props} width="113" height="33" viewBox="0 0 113 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.98076 27.7944H0.561218L11.3624 0.0959473H16.8571L5.98076 27.7944Z" fill="#0F09FF"/>
            <path
                d="M27.3051 6.26075C26.9396 6.26726 26.5766 6.1988 26.2386 6.05959C25.9006 5.92038 25.5947 5.71337 25.3397 5.45134C25.0777 5.19643 24.8708 4.89054 24.7316 4.55253C24.5924 4.21453 24.524 3.85159 24.5305 3.48611C24.5305 2.71538 24.8003 2.06536 25.3397 1.53606C25.8642 1.02695 26.5665 0.742188 27.2974 0.742188C28.0284 0.742188 28.7306 1.02695 29.2551 1.53606C29.7946 2.06554 30.0644 2.71556 30.0644 3.48611C30.0709 3.8516 30.0025 4.21454 29.8633 4.55254C29.7241 4.89055 29.5171 5.19644 29.2551 5.45134C29.0026 5.71231 28.6993 5.91877 28.3639 6.05798C28.0286 6.19719 27.6682 6.2662 27.3051 6.26075ZM24.9159 24.5118V8.72709H29.7715V24.5118L24.9159 24.5118Z"
                fill="black"/>
            <path
                d="M40.0532 24.928C37.7818 24.928 35.9655 24.183 34.604 22.693C33.2422 21.203 32.5614 19.2094 32.5616 16.712C32.5616 14.1739 33.2681 12.1237 34.6811 10.5615C36.0939 8.99943 37.9874 8.2184 40.3615 8.2184C41.3105 8.21314 42.251 8.39656 43.1284 8.758C43.9967 9.11779 44.6569 9.59047 45.1092 10.176V0.711304H49.9649V24.5118H45.3867L45.14 22.4926C44.7084 23.2223 44.0327 23.8106 43.113 24.2576C42.1932 24.7047 41.1732 24.9281 40.0532 24.928ZM41.2247 20.4731C42.3654 20.4731 43.2903 20.1109 43.9994 19.3865C44.7084 18.6619 45.0629 17.7087 45.063 16.5269C45.063 15.3453 44.7084 14.3921 43.9994 13.6676C43.2903 12.9431 42.3654 12.5808 41.2247 12.5808C40.0736 12.5808 39.159 12.943 38.4808 13.6676C37.8026 14.392 37.4635 15.3451 37.4634 16.5269C37.4634 17.7088 37.8026 18.662 38.4808 19.3865C39.1591 20.111 40.0737 20.4732 41.2247 20.4731Z"
                fill="black"/>
            <path
                d="M56.6396 24.928C56.2581 24.9343 55.8793 24.8623 55.5267 24.7165C55.1741 24.5707 54.8551 24.3542 54.5894 24.0803C54.024 23.5152 53.7414 22.8473 53.7415 22.0764C53.7359 21.6984 53.8083 21.3233 53.9542 20.9745C54.1001 20.6258 54.3163 20.3108 54.5894 20.0494C54.8562 19.7775 55.1756 19.5628 55.528 19.4184C55.8805 19.2739 56.2587 19.2028 56.6396 19.2093C57.4204 19.2093 58.1012 19.4918 58.682 20.057C59.2625 20.6222 59.5528 21.2954 59.5529 22.0764C59.5529 22.8472 59.26 23.5151 58.6743 24.0803C58.0885 24.6454 57.4103 24.928 56.6396 24.928Z"
                fill="black"/>
            <path
                d="M63.3296 32.0959V8.72709H67.9077L68.1544 10.654C68.5961 9.92429 69.2821 9.33596 70.2123 8.889C71.1423 8.44192 72.1622 8.21838 73.2721 8.21838C75.502 8.21838 77.3004 8.95056 78.6673 10.4149C80.034 11.8794 80.7173 13.8654 80.7175 16.3728C80.7175 18.9215 80.0572 20.9897 78.7366 22.5773C77.4161 24.165 75.6049 24.9589 73.3029 24.9589C72.2673 24.9669 71.2395 24.7786 70.2739 24.4039C69.3336 24.034 68.6374 23.5459 68.1852 22.9396V32.0959H63.3296ZM69.2874 13.7601C68.573 14.4845 68.2159 15.4377 68.2161 16.6194C68.2161 17.8013 68.5732 18.7544 69.2874 19.4788C70.0015 20.2035 70.929 20.5658 72.0698 20.5656C73.2206 20.5656 74.1352 20.2034 74.8136 19.4788C75.4919 18.7544 75.831 17.8012 75.831 16.6194C75.831 15.4378 75.4919 14.4847 74.8136 13.7601C74.1354 13.0356 73.2208 12.6733 72.0698 12.6733C70.929 12.6733 70.0016 13.0355 69.2874 13.7601Z"
                fill="black"/>
            <path
                d="M94.3751 8.72712V13.3053H92.8335C89.8635 13.3053 88.3786 14.7594 88.3786 17.6678V24.5119H83.523V8.75802H88.1011L88.3478 11.1009C88.6748 10.3262 89.2248 9.66619 89.9279 9.20492C90.6316 8.74248 91.4923 8.51129 92.5099 8.51135C93.1378 8.51149 93.7637 8.58389 94.3751 8.72712Z"
                fill="black"/>
            <path
                d="M97.9514 22.6004C96.3174 21.0693 95.5004 19.0654 95.5004 16.5887C95.5004 14.1121 96.3174 12.103 97.9514 10.5615C99.5853 9.02 101.677 8.24927 104.225 8.24927C106.753 8.24927 108.832 9.02 110.46 10.5615C112.089 12.1029 112.904 14.112 112.904 16.5887C112.904 19.0654 112.092 21.0693 110.468 22.6004C108.844 24.1318 106.763 24.8974 104.225 24.8973C101.676 24.8973 99.5852 24.1316 97.9514 22.6004ZM100.402 16.5578C100.402 17.75 100.754 18.7108 101.458 19.4403C102.162 20.1702 103.084 20.5351 104.225 20.5349C105.345 20.5349 106.255 20.17 106.954 19.4403C107.652 18.7108 108.002 17.75 108.002 16.5578C108.002 15.3761 107.652 14.423 106.954 13.6984C106.255 12.9738 105.345 12.6115 104.225 12.6116C103.084 12.6116 102.162 12.9739 101.458 13.6984C100.754 14.4229 100.402 15.376 100.402 16.5578Z"
                fill="black"/>
        </svg>

    );
}

export default LogoSVG;